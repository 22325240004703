html, body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  background: #fff;
}

.MuiSnackbar-root {
  position: fixed;
  right: 8px;
  bottom: 8px;
  left: auto;
}
@media (min-width: 600px) {
  .MuiSnackbar-root {
    position: fixed;
    right: 24px;
    bottom: 24px;
    left: auto;
  }
}

@media (max-width: 1200px) {
  .ant-modal,
  .ant-modal-content,
  .ant-modal-centered .ant-modal {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }

  .ant-modal-body {
    height: calc(100vh - 110px);
    overflow: auto;
  }

  .ant-modal-centered {
    text-align: unset;
  }
}

/* antd z-index conflicts with MUI's zIndices. We should delete these after migrating to Antd */
/* .ant-modal-wrap {
  z-index: 1500;
} */
.ant-select-dropdown,
.ant-tooltip {
  z-index: 1700;
}
.ant-notification {
  z-index: 1550;
}

/* This style is used because antd has width: 100% and it breaks mui styles */
legend {
  width: unset;
}
/* This style is used because antd has default margins and it breaks mui styles */
p {
  margin-top: unset;
  margin-bottom: unset;
}

.secondary {
  font-size: 0.875rem;
  color: rgba(0, 0, 0,0.6);
}

.primary {
  font-size: 0.875rem;
}